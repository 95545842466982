import { langConfig } from './config/langConfig';

// Define getlangConfig before using it
const getlangConfig = (/** @type {string} */ key, lang = document.documentElement.lang, theLangconfig = langConfig) => {
    return theLangconfig[lang] ? theLangconfig[lang][key] : null;
};

// Define generatePageId after getlangConfig is defined
function generatePageId(path = window.location.pathname) {
    // remove trailing slash if present
    path = path.replace(/[/]*$/g, '');
    // get last part of path
    const lastPart = path.split('/').pop();
    const lastPartHasExtension = lastPart.indexOf('.') > -1;
    // if last part has extension, remove extension
    if (lastPartHasExtension) {
        // remove extension
        path = path.split('/');
        path.pop();
        path = path.join('/');
    }
    const prefixpageid = getlangConfig('domainid', document.documentElement.lang).toUpperCase();
    return (prefixpageid + path).toUpperCase();
}

// assign emos3 to window object
window.emos3 = window.emos3 || {};
// set pageId to emos3 for the emosclickmonitor
window.emos3.pageId = generatePageId();

export default generatePageId;
